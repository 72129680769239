import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Button,
	FormFeedback
} from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Add_Calendar = () => {

	const [data, setData] = useState({
		name: "",
		slug: "",
		sku: "",
		summary: "",
		category: "",
		catalogue: "",
		design: "",
		language: "",
		size: "",
		userQuantity: "",
		description: "",
		paperSize: '',
		paperThickness: '',
		paperSheets: '',
		images: [],
		variations: [{
			quantity: '',
			price: ''
		}]
	});
	const [uniqueId, setUniqueId] = useState(Date.now());
	const [file, setFile] = useState([]);
	const [selectedVariationIndex, setSelectedVariationIndex] = useState(0);
	const {
		handleSubmit,
		control,
		getValues,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: data
	});
	const fileChange = (e) => {
		setFile([...file, ...e.target.files]);
	};

	const clearFileState = () => {
		setFile([]);
	};

	const [customErrors, setCustomErrors] = useState({});

	const handleValidSubmit = async (data) => {
		console.log(data, "data");
		try {
			const formData = new FormData();
			const values = getValues();

			for (var j = 0; j < file.length; j++) {
				formData.append("images", file[j]);
			}

			// for (const [key, value] of Object.entries(data)) {
			// 	if (key === "size") {
			// 		if (Array.isArray(value)) {
			// 			// If "size" is an array, store its values individually in FormData
			// 			for (let i = 0; i < value.length; i++) {
			// 				formData.append("size", value[i]);
			// 			}
			// 		} else {
			// 			// If "size" is not an array, store it as a single value in FormData
			// 			formData.append("size", value);
			// 		}
			// 	} else {
			// 		formData.append(key, value);
			// 	}
			// }

			Object.keys(data).forEach((key) => {
				if (key === 'images') {
					data.images.forEach((image, index) => {
						formData.append(`images[${index}]`, image);
					});
				} else if (key === 'variations') {
					data.variations.forEach((variation, index) => {
						Object.keys(variation).forEach((variationKey) => {
							formData.append(`variations[${index}][${variationKey}]`, variation[variationKey]);
						});
					});
				} else {
					formData.append(key, data[key]);
				}
			});
			const response = await axios.post(
				process.env.REACT_APP_API_URL + "calendar/create",
				formData,
				{
					headers: {
						"Content-type": "multipart/form-data",
						//Authorization: `${token && token.token}`,
					},
				}
			);

			if (response.data.success) {
				toast.success("Product added successfully");
				reset();
				clearFileState();
				window.location.reload();
			} else {
				toast.error("An error occurred while creating the product");
			}
		} catch (error) {
			if (error.response && error.response.status === 400) {
				const { data } = error.response;
				if (data && data.message) {
					if (Array.isArray(data.message)) {
						toast.error(data.message[0]);
					} else {
						toast.error(data.message);
					}
				} else {
					toast.error("An error occurred while processing the request");
				}
			} else if (error.request) {
				toast.error("No response received from the server");
			} else {
				toast.error("An error occurred while sending the request");
			}
		}
	};

	const CustomMDEditor = ({ name, control }) => {
		return (
			<Controller
				name={name}
				control={control}
				defaultValue={data.description}
				render={({ field }) => (
					<MDEditor value={field.value} onChange={field.onChange} />
				)}
			/>
		);
	};

	function deleteFile(e) {
		const s = file.filter((item, index) => index !== e);
		setFile(s);
		setUniqueId(Date.now());
	}

	const addVariation = () => {
		console.log("Adding new variation");
		const newVariation = {
			quantity: "",
			price: "",
		};

		setData((prevData) => {
			const updatedVariations = [...prevData.variations, newVariation];

			return {
				...prevData,
				variations: updatedVariations,
			};
		});
	};



	const removeVariation = (indexToRemove) => {
		setData((prevData) => {
			const updatedVariations = prevData.variations.filter((_, index) => index !== indexToRemove);

			return {
				...prevData,
				variations: updatedVariations,
			};
		});
	};

	return (
		<Fragment>
			<Breadcrumb title="Add Calendar" parent="Physical" />
			<ToastContainer />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Add Calendar</h5>
							</CardHeader>
							<CardBody>
								<Row className="product-adding">
									<Col xl="3">
										<div className="add-product">
											<Col xl="12">
												<div className="add-product">
													{file && file.length < 6 && (
														<Row>
															<Col xl="12 xl-50" sm="6 col-3">
																<Input
																	key={uniqueId}
																	className="upload"
																	type="file"
																	multiple
																	name="images"
																	accept="image/png, image/gif,image/webp, image/jpeg"
																	onChange={fileChange}
																/>
															</Col>
														</Row>
													)}
													<Row style={{ marginTop: "15px" }}>
														{file &&
															file.length > 0 &&
															file.map((item, index) => {
																return (
																	<Col xl="6" sm="3 col-3">
																		<div key={index}>
																			<img
																				style={{ width: "100%" }}
																				src={URL.createObjectURL(item)}
																				alt=""
																			/>
																			<Button
																				style={{ marginTop: "10px" }}
																				type="button"
																				color="secondary"
																				size="sm"
																				onClick={() => deleteFile(index)}
																			>
																				delete
																			</Button>
																		</div>
																	</Col>
																);
															})}
													</Row>
												</div>
											</Col>
										</div>
									</Col>
									<Col xl="12">
										<Form
											className="needs-validation add-product-form"
											onSubmit={handleSubmit(handleValidSubmit)}
										>

											<div className="form form-label-center">
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Product Name:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="name"
															control={control}
															defaultValue={data.name}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.name}
																	/>
																	{errors.name && (
																		<FormFeedback>{errors.name.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Product Name is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Product URL:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="slug"
															control={control}
															defaultValue={data.slug}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.slug}
																	/>
																	{errors.slug && (
																		<FormFeedback>{errors.slug.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Product URL is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														SKU:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="sku"
															control={control}
															defaultValue={data.sku}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.sku}
																	/>
																	{errors.sku && (
																		<FormFeedback>{errors.sku.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "SKU is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Catalogue:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="catalogue"
															control={control}
															defaultValue={data.catalogue}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.catalogue}>
																		<option value="">Select Catalogue</option>
																		<option value="Glory">Glory</option>
																		<option value="Jeba">Jeba</option>
																		<option value="CPM">CPM</option>
																		<option value="Sharon">Sharon</option>
																		<option value="Olive">Olive</option>
																		<option value="TPP">TPP</option>
																	</Input>
																	{errors.catalogue && (
																		<FormFeedback>{errors.catalogue}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Catalogue is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Design:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="design"
															control={control}
															defaultValue={data.design}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.design}>
																		<option value="">Select Design</option>
																		{/* Generate options from 1 to 100 */}
																		{Array.from({ length: 100 }, (_, index) => (
																			<option key={index + 1} value={`design${index + 1}`}>
																				Design {index + 1}
																			</option>
																		))}
																	</Input>
																	{errors.design && (
																		<FormFeedback>{errors.design}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Design is required" }}
														/>
													</div>
												</FormGroup>


												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Language:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="language"
															control={control}
															defaultValue={data.language}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.language}>
																		<option value="">Select Language</option>
																		<option value="Tamil">Tamil</option>
																		<option value="English">English</option>
																		<option value="Hindi">Hindi</option>
																		<option value="Telugu">Telugu</option>
																		<option value="Malayalam">Malayalam</option>
																		<option value="Kannada">Kannada</option>
																	</Input>
																	{errors.catalogue && (
																		<FormFeedback>{errors.catalogue}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Catalogue is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Paper Size:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="paperSize"
															control={control}
															defaultValue={data.paperSize}
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.paperSize}>
																		<option value="">Select Paper Size</option>
																		<option value="15x20">15x20</option>
																		<option value="18x23">18x23</option>
																		<option value="18x25">18x25</option>
																	</Input>
																	{errors.paperSize && (
																		<FormFeedback>{errors.paperSize}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Paper Size is required" }}
														/>
													</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														Paper Thickness:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="paperThickness" // Update the name based on the selected variation
															control={control}
															defaultValue={data.paperThickness} // Set the default value based on the selected variation
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.paperThickness}>
																		<option value="">Select Paper Thickness</option>
																		<option value="100gsm">100 gsm</option>
																		<option value="128gsm">128 gsm</option>
																		<option value="157gsm">157 gsm</option>
																		<option value="170gsm">170 gsm</option>
																	</Input>
																	{errors.paperThickness && (
																		<FormFeedback>{errors.paperThickness}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Frame Type is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-6 mb-0">
														No of Sheets:
													</Label>
													<div className="col-xl-6 col-sm-7">
														<Controller
															name="paperSheets" // Update the name based on the selected variation
															control={control}
															defaultValue={data.paperSheets} // Set the default value based on the selected variation
															render={({ field }) => (
																<>
																	<Input type="select" className="form-control" {...field} invalid={!!errors.paperSheets}>
																		<option value="">Select Paper Sheeter</option>
																		<option value="3 Sheeter">3 Sheeters</option>
																		<option value="6 Sheeter">6 Sheeters</option>
																	</Input>
																	{errors.paperSheets && (
																		<FormFeedback>{errors.paperSheets}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Paper Sheeter is required" }}
														/>
													</div>
												</FormGroup>


												<div className="board-variation">
													{data.variations && data.variations.map((variation, index) => (
														<div key={index} className="board-container">
															<FormGroup className="form-group mb-3 row">
																<Label className="col-xl-4 col-sm-6 mb-0">Quantity:</Label>
																<div className="col-xl-8 col-sm-7">
																	<Controller
																		name={`variations[${index}].quantity`}
																		control={control}
																		defaultValue={variation.quantity}
																		render={({ field }) => (
																			<>
																				<Input
																					type="select"
																					className="form-control"
																					{...field}
																					invalid={!!errors.variations?.[index]?.quantity}
																					onChange={(e) => { field.onChange(e); }}
																				>
																					<option value="">Select Quantity</option>
																					<option value="50-100">50-100</option>
																					<option value="100-200">100-200</option>
																					<option value="200-300">200-300</option>
																					<option value="300-400">300-400</option>
																					<option value="400-500">400-500</option>
																					<option value="500-600">500-600</option>
																					<option value="600-700">600-700</option>
																					<option value="700-800">700-800</option>
																					<option value="800-900">800-900</option>
																					<option value="900-1000">900-1000</option>
																				</Input>
																				{errors.variations?.[index]?.quantity && (
																					<FormFeedback>{errors.variations[index].quantity.message}</FormFeedback>
																				)}
																			</>
																		)}
																		rules={{ required: "Quantity is required" }}
																	/>
																</div>
															</FormGroup>

															<FormGroup className="form-group mb-3 row">
																<Label className="col-xl-4 col-sm-6 mb-0">Price (per calendar):</Label>
																<div className="col-xl-8 col-sm-7">
																	<Controller
																		name={`variations[${index}].price`}
																		control={control}
																		defaultValue={variation.price}
																		render={({ field }) => (
																			<>
																				<Input
																					type="text"
																					className="form-control"
																					{...field}
																					invalid={!!errors.variations?.[index]?.price}
																					onChange={(e) => { field.onChange(e); }}
																				/>
																				{errors.variations?.[index]?.price && (
																					<FormFeedback>{errors.variations[index].price.message}</FormFeedback>
																				)}
																			</>
																		)}
																		rules={{ required: "Price is required" }}
																	/>
																</div>
															</FormGroup>

															<div className="button-group">
																{/* Only show Remove button if index is greater than 0 */}
																{index > 0 && (
																	<Button
																		type="button"
																		onClick={() => removeVariation(index)}
																		className="remove-btn"
																	>
																		Remove
																	</Button>
																)}
																{index === data.variations.length - 1 && (
																	<Button
																		type="button"
																		onClick={addVariation}
																		className="add-btn"
																	>
																		Add
																	</Button>
																)}
															</div>
														</div>
													))}
												</div>


												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Summary:
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Controller
															name="summary"
															control={control}
															defaultValue={data.summary}
															render={({ field }) => (
																<>
																	<Input
																		className="form-control"
																		{...field}
																		type="text"
																		invalid={!!errors.summary}
																	/>
																	{errors.summary && (
																		<FormFeedback>{errors.summary.message}</FormFeedback>
																	)}
																</>
															)}
															rules={{ required: "Summary is required" }}
														/>
													</div>
												</FormGroup>

												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4">
														Add Description :
													</Label>
													<div className="col-xl-8 col-sm-7 description-sm">
														<CustomMDEditor name="description" control={control} />
													</div>
												</FormGroup>
											</div>

											<div className="offset-xl-3 offset-sm-4">
												<Button type="submit" color="primary">
													Add
												</Button>
												<Button type="button" color="light">
													Discard
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Add_Calendar;

import React, { Fragment, useState, useEffect } from "react";
//import data from "../../../assets/data/physical_list";
import { Edit, Trash2 } from "react-feather";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";

const Categolue5 = () => {
    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + 'calendar/calendarList');
            setAllProducts(response.data.calendars);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const deleteProduct = (productId) => {
		axios.delete(process.env.REACT_APP_API_URL + `calendar/delete/${productId}`)
			.then((response) => {
				toast.success('Product deleted successfully');
				// Remove the deleted product from the state
				setAllProducts(allProducts.filter((product) => product._id !== productId));
			})
			.catch((error) => {
				console.error('Error deleting product:', error);
			});
	};

    return (
        <Fragment>
            <ToastContainer />
           
            <Container fluid={true}>


                <div className="tabs">
                    <div className="tab-content">

                        <>
                            <div>
                                <h2>Tamil Calendar</h2>
                                <Row className="products-admin ratio_asos">
                                    <p>{allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Tamil').length} products</p>
                                    {allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Tamil').reverse().map((myData, i) => {
                                            return (
                                                <Col xl="3" sm="6" key={i}>
                                                    <Card>
                                                        <div className="products-admin">

                                                            <CardBody className="product-box">
                                                                <div className="img-wrapper">

                                                                    <div className="lable-block">
                                                                        {myData.tag === "new" ? (
                                                                            <span className="lable3">{myData.tag}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {myData.discount === "on sale" ? (
                                                                            <span className="lable4">{myData.offerPercentage}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="front">
                                                                        <a href="/#" className="bg-size">
                                                                            <img
                                                                                alt=""
                                                                                className="img-fluid blur-up bg-img lazyloaded"
                                                                                src={myData.images[0]}
                                                                            />
                                                                        </a>
                                                                        <div className="product-hover">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/products/physical/update-calendar/${myData._id}`}>
                                                                                        <Button color="btn" type="button">
                                                                                            <Edit className="editBtn" />
                                                                                        </Button>
                                                                                    </Link>

                                                                                </li>
                                                                                <li>
                                                                                    <Button color="btn" type="button">
                                                                                        <Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-detail">
                                                                    <a href="/#">
                                                                        {" "}
                                                                        <h6>{myData.name}</h6>
                                                                    </a>
                                                                    {/* <h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4> */}
                                                                </div>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>


                            <div>
                                <h2>English Calendar</h2>
                                <Row className="products-admin ratio_asos">
                                <p>{allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'English').length} products</p>
                                    {allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'English').reverse().map((myData, i) => {
                                            return (
                                                <Col xl="3" sm="6" key={i}>
                                                    <Card>
                                                        <div className="products-admin">

                                                            <CardBody className="product-box">
                                                                <div className="img-wrapper">

                                                                    <div className="lable-block">
                                                                        {myData.tag === "new" ? (
                                                                            <span className="lable3">{myData.tag}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {myData.discount === "on sale" ? (
                                                                            <span className="lable4">{myData.offerPercentage}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="front">
                                                                        <a href="/#" className="bg-size">
                                                                            <img
                                                                                alt=""
                                                                                className="img-fluid blur-up bg-img lazyloaded"
                                                                                src={myData.images[0]}
                                                                            />
                                                                        </a>
                                                                        <div className="product-hover">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/products/physical/update-product/${myData._id}`}>
                                                                                        <Button color="btn" type="button">
                                                                                            <Edit className="editBtn" />
                                                                                        </Button>
                                                                                    </Link>

                                                                                </li>
                                                                                <li>
                                                                                    <Button color="btn" type="button">
                                                                                        <Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-detail">
                                                                    <a href="/#">
                                                                        {" "}
                                                                        <h6>{myData.name}</h6>
                                                                    </a>
                                                                    {/* <h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4> */}
                                                                </div>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>

                            <div>
                                <h2>Malayalam Calendar</h2>
                                <Row className="products-admin ratio_asos">
                                <p>{allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Malayalam').length} products</p>
                                    {allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Malayalam').reverse().map((myData, i) => {
                                            return (
                                                <Col xl="3" sm="6" key={i}>
                                                    <Card>
                                                        <div className="products-admin">

                                                            <CardBody className="product-box">
                                                                <div className="img-wrapper">

                                                                    <div className="lable-block">
                                                                        {myData.tag === "new" ? (
                                                                            <span className="lable3">{myData.tag}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {myData.discount === "on sale" ? (
                                                                            <span className="lable4">{myData.offerPercentage}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="front">
                                                                        <a href="/#" className="bg-size">
                                                                            <img
                                                                                alt=""
                                                                                className="img-fluid blur-up bg-img lazyloaded"
                                                                                src={myData.images[0]}
                                                                            />
                                                                        </a>
                                                                        <div className="product-hover">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/products/physical/update-product/${myData._id}`}>
                                                                                        <Button color="btn" type="button">
                                                                                            <Edit className="editBtn" />
                                                                                        </Button>
                                                                                    </Link>

                                                                                </li>
                                                                                <li>
                                                                                    <Button color="btn" type="button">
                                                                                        <Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-detail">
                                                                    <a href="/#">
                                                                        {" "}
                                                                        <h6>{myData.name}</h6>
                                                                    </a>
                                                                    {/* <h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4> */}
                                                                </div>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>

                            <div>
                                <h2>Kannada Calendar</h2>
                                <Row className="products-admin ratio_asos">
                                <p>{allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Kannada').length} products</p>
                                    {allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Kannada').reverse().map((myData, i) => {
                                            return (
                                                <Col xl="3" sm="6" key={i}>
                                                    <Card>
                                                        <div className="products-admin">

                                                            <CardBody className="product-box">
                                                                <div className="img-wrapper">

                                                                    <div className="lable-block">
                                                                        {myData.tag === "new" ? (
                                                                            <span className="lable3">{myData.tag}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {myData.discount === "on sale" ? (
                                                                            <span className="lable4">{myData.offerPercentage}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="front">
                                                                        <a href="/#" className="bg-size">
                                                                            <img
                                                                                alt=""
                                                                                className="img-fluid blur-up bg-img lazyloaded"
                                                                                src={myData.images[0]}
                                                                            />
                                                                        </a>
                                                                        <div className="product-hover">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/products/physical/update-product/${myData._id}`}>
                                                                                        <Button color="btn" type="button">
                                                                                            <Edit className="editBtn" />
                                                                                        </Button>
                                                                                    </Link>

                                                                                </li>
                                                                                <li>
                                                                                    <Button color="btn" type="button">
                                                                                        <Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-detail">
                                                                    <a href="/#">
                                                                        {" "}
                                                                        <h6>{myData.name}</h6>
                                                                    </a>
                                                                    {/* <h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4> */}
                                                                </div>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>

                            <div>
                                <h2>Telugu Calendar</h2>
                                <Row className="products-admin ratio_asos">
                                <p>{allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Telugu').length} products</p>
                                    {allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Telugu').reverse().map((myData, i) => {
                                            return (
                                                <Col xl="3" sm="6" key={i}>
                                                    <Card>
                                                        <div className="products-admin">

                                                            <CardBody className="product-box">
                                                                <div className="img-wrapper">

                                                                    <div className="lable-block">
                                                                        {myData.tag === "new" ? (
                                                                            <span className="lable3">{myData.tag}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {myData.discount === "on sale" ? (
                                                                            <span className="lable4">{myData.offerPercentage}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="front">
                                                                        <a href="/#" className="bg-size">
                                                                            <img
                                                                                alt=""
                                                                                className="img-fluid blur-up bg-img lazyloaded"
                                                                                src={myData.images[0]}
                                                                            />
                                                                        </a>
                                                                        <div className="product-hover">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/products/physical/update-product/${myData._id}`}>
                                                                                        <Button color="btn" type="button">
                                                                                            <Edit className="editBtn" />
                                                                                        </Button>
                                                                                    </Link>

                                                                                </li>
                                                                                <li>
                                                                                    <Button color="btn" type="button">
                                                                                        <Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-detail">
                                                                    <a href="/#">
                                                                        {" "}
                                                                        <h6>{myData.name}</h6>
                                                                    </a>
                                                                    {/* <h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4> */}
                                                                </div>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>

                            <div>
                                <h2>Hindi Calendar</h2>
                                <Row className="products-admin ratio_asos">
                                <p>{allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Hindi').length} products</p>
                                    {allProducts && allProducts
                                        .filter(product => product.category === 'calendar' && product.catalogue === 'Olive' && product.language === 'Hindi').reverse().map((myData, i) => {
                                            return (
                                                <Col xl="3" sm="6" key={i}>
                                                    <Card>
                                                        <div className="products-admin">

                                                            <CardBody className="product-box">
                                                                <div className="img-wrapper">

                                                                    <div className="lable-block">
                                                                        {myData.tag === "new" ? (
                                                                            <span className="lable3">{myData.tag}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {myData.discount === "on sale" ? (
                                                                            <span className="lable4">{myData.offerPercentage}</span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="front">
                                                                        <a href="/#" className="bg-size">
                                                                            <img
                                                                                alt=""
                                                                                className="img-fluid blur-up bg-img lazyloaded"
                                                                                src={myData.images[0]}
                                                                            />
                                                                        </a>
                                                                        <div className="product-hover">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/products/physical/update-product/${myData._id}`}>
                                                                                        <Button color="btn" type="button">
                                                                                            <Edit className="editBtn" />
                                                                                        </Button>
                                                                                    </Link>

                                                                                </li>
                                                                                <li>
                                                                                    <Button color="btn" type="button">
                                                                                        <Trash2 className="deleteBtn" onClick={() => deleteProduct(myData._id)} />
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="product-detail">
                                                                    <a href="/#">
                                                                        {" "}
                                                                        <h6>{myData.name}</h6>
                                                                    </a>
                                                                    {/* <h4>
																		<del>Rs.{myData.regularPrice}</del> Rs.{myData.offerPrice}
																	</h4> */}
                                                                </div>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>
                        </>
                    </div>
                </div>


            </Container>
        </Fragment>
    );
};

export default Categolue5;

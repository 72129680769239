import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import {  useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
  import 'react-toastify/dist/ReactToastify.css';
const LoginTabset = () => {
	const navigate = useNavigate();

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	// const routeChange = () => {
	// 	history(`${process.env.PUBLIC_URL}/dashboard`);
	// };


	// login Api
	const [data, setData] = useState({
		email: "",
		password: "",
	  });
	
	  const handleChange = (event) => {
		event.preventDefault();
		setData({ ...data, [event.target.name]: event.target.value });
	  };
	
	  const handleSubmit = async (e) => {
		e.preventDefault();

		if (!data.email) {
			toast.error('Please enter email');
			return;
		  }
	
		  if (!data.password) {
			toast.error('Please enter password');
			return;
		  }
	
		const loginData = {
		  email: data.email,
		  password: data.password,
		};
		const res = await axios.post( process.env.REACT_APP_API_URL  + "admin/auth/login",
		  loginData
		);
		if (res.status === 200) {
			toast.success("Logged In successfully");
		  localStorage.setItem("admin", JSON.stringify(res.data));
		  window.location.href = `${process.env.PUBLIC_URL || ""}/dashboard`;
		}
	  };

	return (
		<div>
			<ToastContainer />
			<Fragment>
				<Tabs>
					<h2 style={{textAlign:"center",padding:"20px 0",color:"#0d5583"}}>Welcome to Infoston Store</h2>
					<TabList className="nav nav-tabs tab-coupon">
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<User />
							Login
						</Tab>
						{/* <Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<Unlock />
							Register
						</Tab> */}
					</TabList>

					<TabPanel>
						<Form className="form-horizontal auth-form" onSubmit={handleSubmit}>
							<FormGroup>
								<Input
									required=""
									name="email"
									type="email"
									className="form-control"
									placeholder="Username"
									id="exampleInputEmail1"
									onChange={handleChange}
                                    value={data && data.email}
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="password"
									type="password"
									className="form-control"
									placeholder="Password"
									onChange={handleChange}
                                    value={data && data.password}
								/>
							</FormGroup>
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
								>
									Login
								</Button>
							</div>
						</Form>
					</TabPanel>
					<TabPanel>
						<Form className="form-horizontal auth-form">
							<FormGroup>
								<Input
									required=""
									name="login[username]"
									type="email"
									className="form-control"
									placeholder="Username"
									id="exampleInputEmail12"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Password"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Confirm Password"
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox me-sm-2">
									<Label className="d-block">
										<Input
											className="checkbox_animated"
											id="chk-ani2"
											type="checkbox"
										/>
										I agree all statements in{" "}
										<span>
											<a href="/#">Terms &amp; Conditions</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
								>
									Register
								</Button>
							</div>
							<div className="form-footer">
								<span>Or Sign up with social platforms</span>
								<ul className="social">
									<li>
										<a href="/#">
											<i className="icon-facebook"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-twitter-alt"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-instagram"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-pinterest-alt"></i>
										</a>
									</li>
								</ul>
							</div>
						</Form>
					</TabPanel>
				</Tabs>
			</Fragment>
		</div>
	);
};

export default LoginTabset;

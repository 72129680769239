import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CalendarOrdersAdmin = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [products, setProducts] = useState({});

    // Fetch the orders when the component mounts
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                // Make the API call to fetch the calendar orders
                const response = await axios.get(process.env.REACT_APP_API_URL + 'calendarOrder/calendarOrderList');
                const orders = response.data.data.orders;
                setOrders(orders); // Set the fetched orders in state

                // Fetch product details for each order using the slug instead of productId
                const productPromises = orders.map(order => {
                    if (!products[order.productId]) {
                        return axios.get(process.env.REACT_APP_API_URL + `calendarOrder/${order.productId}`); // Here, productId is the slug
                    } else {
                        return null; // If already fetched, return null
                    }
                });

                const productResponses = await Promise.all(productPromises.filter(Boolean)); // Remove null values

                // Update products state with fetched product details
                productResponses.forEach(productResponse => {
                    const product = productResponse.data;
                    setProducts(prevProducts => ({
                        ...prevProducts,
                        [product.slug]: product // Use the product's slug as the key
                    }));
                });

                setLoading(false); // Set loading to false once data is fetched
            } catch (error) {
                setError('Error fetching orders');
                setLoading(false); // Set loading to false in case of error
            }
        };

        fetchOrders();
    }, [products]);  // Make sure to include products in the dependency array


    // Render loading, error, or the orders list
    return (
        <div>
            <h2>Calendar Orders</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <table className='calendar-order'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>ordered Product</th>
                            <th>Selected Quantity</th>
                            <th>Range & Price </th>
                            <th>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders.map((order) => (
                            <tr key={order._id}>
                                <td>{order.name}</td>
                                <td>{order.email}</td>
                                <td>{order.phone}</td>
                                <td>
                                    {products[order.productId] ? (
                                        <a href={`https://infoston.store/calendar/product-detail/${products[order.productId].slug}`} target="_blank">
                                            {products[order.productId].name}
                                        </a>
                                    ) : (
                                        'Loading...'
                                    )}
                                </td>
                                <td>{order.userEnteredQuantity}</td>
                                <td>{order.selectedQuantity}, {order.selectedAmount}</td>
                                <td>{order.totalAmount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default CalendarOrdersAdmin;

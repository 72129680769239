import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
//import data from "../../../assets/data/physical_list";
import { Edit, Trash2 } from "react-feather";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Catelogue1 from "../../calendars/catelogue1";
import Categolue2 from "../../calendars/catelogue2";
import Categolue3 from "../../calendars/catelogue3";
import Categolue4 from "../../calendars/catelogue4";
import Categolue5 from "../../calendars/catelogue5";
import Categolue6 from "../../calendars/catelogue6";

const Calendar_list = () => {
	const [allProducts, setAllProducts] = useState([]);
	const [bibleProducts, setBibleProducts] = useState([]);
	const [weddingBibleProducts, setWeddingBibleProducts] = useState([]);

	const [selectedCategory, setSelectedCategory] = useState("All");
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [productCount, setProductCount] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");

	const [activeTab, setActiveTab] = useState('tab1');

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_API_URL + 'calendar/calendarList');
			setAllProducts(response.data.calendars);
		} catch (error) {
			console.error('Error fetching products:', error);
		}
	};

	const deleteProduct = (productId) => {
		axios.delete(process.env.REACT_APP_API_URL + `calendar/delete/${productId}`)
			.then((response) => {
				toast.success('Product deleted successfully');
				// Remove the deleted product from the state
				setAllProducts(allProducts.filter((product) => product._id !== productId));
			})
			.catch((error) => {
				console.error('Error deleting product:', error);
			});
	};


	useEffect(() => {
		// Filter products based on the selected category
		const filtered = selectedCategory === "All"
			? allProducts
			: allProducts.filter(product => product.category === selectedCategory);

		setFilteredProducts(filtered);
		setProductCount(filtered.length); // Update the product count
	}, [selectedCategory, allProducts]);


	useEffect(() => {
		// Filter products based on the search term from the original products list
		const filteredBySearch = allProducts.filter(product => {
			const propertyValue = product.name;
			if (propertyValue) {
				return propertyValue.toLowerCase().includes(searchTerm.toLowerCase());
			}

		});

		setProductCount(filteredBySearch.length);
		setFilteredProducts(filteredBySearch);
	}, [searchTerm, allProducts]);

	return (
		<Fragment>
			<ToastContainer />
			<Breadcrumb title="Calendar List" parent="Physical" />
			<Container fluid={true}>
				{/* <div>
        <label>Filter by Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="All">All Categories</option>
          <option value="HolyBible">Holy Bible</option>
          <option value="WeddingBible">Wedding Bible</option>
		  <option value="Board">Board</option>
        </select>
      </div>
	  <div>
        <label>Search by Product Name:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}

				<div className="tabs">
					<div className="tab-buttons">
						<button
							className={activeTab === 'tab1' ? 'active' : ''}
							onClick={() => handleTabClick('tab1')}
						>
							Catalogue 1 (CPM)
						</button>
						<button
							className={activeTab === 'tab2' ? 'active' : ''}
							onClick={() => handleTabClick('tab2')}
						>
							Catalogue 2 (Glory)
						</button>
						<button
							className={activeTab === 'tab3' ? 'active' : ''}
							onClick={() => handleTabClick('tab3')}
						>
							Catalogue 3 (Jeba)
						</button>
						<button
							className={activeTab === 'tab4' ? 'active' : ''}
							onClick={() => handleTabClick('tab4')}
						>
							Catalogue 4 (Olive)
						</button>
						<button
							className={activeTab === 'tab5' ? 'active' : ''}
							onClick={() => handleTabClick('tab5')}
						>
							Catalogue 5 (Sharon)
						</button>
						<button
							className={activeTab === 'tab6' ? 'active' : ''}
							onClick={() => handleTabClick('tab6')}
						>
							Catalogue 6 (TPP)
						</button>
					</div>

					<div className="tab-content">
						{activeTab === 'tab1' && (
							<Catelogue1 />
						)}
						{activeTab === 'tab2' && (	
							<Categolue2 />
						)}
						{activeTab === 'tab3' && (
							<Categolue3 />
						)}
						{activeTab === 'tab4' && (
							<Categolue4 />
						)}
						{activeTab === 'tab5' && (
							<Categolue5 />
						)}
						{activeTab === 'tab6' && (
							<Categolue6 />
						)}
					</div>
				</div>


			</Container>
		</Fragment>
	);
};

export default Calendar_list;

import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";

const Datatable = ({ myData, myClass, multiSelectOption, pagination }) => {
	const [open, setOpen] = useState(false);
	const [checkedValues, setCheckedValues] = useState([]);
	const [data, setData] = useState(myData);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const selectRow = (e, i) => {
		if (!e.target.checked) {
			setCheckedValues(checkedValues.filter((item, j) => i !== item));
		} else {
			checkedValues.push(i);
			setCheckedValues(checkedValues);
		}
	};

	const [orders, setOrders] = useState([]);

	useEffect(() => {
		// Fetch orders when the component mounts
		axios.get(process.env.REACT_APP_API_URL + 'order/get-all-orders') // Adjust the URL to match your API endpoint
			.then(response => {
				const fetchedOrders = response.data.data.orders;

			// Reverse the array to show the latest order first
			const reversedOrders = fetchedOrders.reverse();

			setOrders(reversedOrders);
			console.log(reversedOrders);
			})
			.catch(error => {
				console.error('Error fetching orders:', error);
			});
	}, []);

	const handleDeleteOrder = (orderId) => {
		axios
			.delete(process.env.REACT_APP_API_URL + `order/delete-order/${orderId}`) // Adjust the URL to match your API endpoint
			.then((response) => {
				// Check if the delete request was successful (status code 200)
				if (response.status === 200) {
					// Filter out the deleted order from the 'orders' state
					const updatedOrders = orders.filter((order) => order._id !== orderId);
					setOrders(updatedOrders); // Update the 'orders' state with the modified data
					toast.success("Order deleted successfully");
				} else {
					toast.error("Failed to delete order");
				}
			})
			.catch((error) => {
				console.error("Error deleting order:", error);
				toast.error("Failed to delete order");
			});
	};



	const handleRemoveRow = () => {
		const updatedData = myData.filter(function (el) {
			return checkedValues.indexOf(el.id) < 0;
		});
		setData([...updatedData]);
		toast.success("Successfully Deleted !");
	};

	const renderEditable = (cellInfo) => {
		return (
			<div
				style={{ backgroundColor: "#fafafa" }}
				contentEditable
				suppressContentEditableWarning
				onBlur={(e) => {
					data[cellInfo.index][cellInfo.index.id] = e.target.innerHTML;
					setData({ myData: data });
				}}
				dangerouslySetInnerHTML={{
					__html: myData[cellInfo.index][cellInfo.index.id],
				}}
			/>
		);
	};

	const handleDelete = (index) => {
		if (window.confirm("Are you sure you wish to delete this item?")) {
			const del = data;
			del.splice(index, 1);
			setData([...del]);
		}
		toast.success("Successfully Deleted !");
	};
	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const handleShowOrderDetails = (order) => {
		setSelectedOrder(order);
		setOpen(true);
	};

	const Capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};



	const selectedFields = ["name", "email", "phone", "createdAt"];

	const statusOptions = ['Processing', 'Shipped', 'Delivered']; // Define your status options here

	const courierOptions = ['India Post', 'Ecom Express', 'FedEx', 'Delhivery', 'ST Courier', 'Professional Courier', 'DTDC Courier'];

	const handleStatusChange = (orderId, newStatus) => {
		let updateFields = { orderStatus: newStatus };

		if (newStatus === 'Shipped') {
			updateFields.shippedDate = new Date(); // Set shippedDate to the current date
		} else if (newStatus === 'Delivered') {
			updateFields.deliveredDate = new Date(); // Set deliveredDate to the current date
		}

		// Make an API call to update the order status including the date fields
		axios.put(`${process.env.REACT_APP_API_URL}order/update-status/${orderId}`, updateFields)
			.then(response => {
				// Assuming the backend returns updated order data after successful status update
				const updatedOrders = orders.map(order => {
					if (order._id === orderId) {
						return { ...order, ...updateFields }; // Update order with the new status and date fields
					}
					return order;
				});
				setOrders(updatedOrders);
				toast.success(`Order status updated to ${newStatus}`);
			})
			.catch(error => {
				console.error('Error updating order status:', error);
				toast.error('Failed to update order status');
			});
	};

	const handleCourierPartnerChange = (orderId, newCourierPartner) => {
		// Make an API call to update the courier partner for the order
		axios.put(`${process.env.REACT_APP_API_URL}order/update-status/${orderId}`, { courierPartner: newCourierPartner })
			.then(response => {
				// Assuming the backend returns updated order data after successful update
				const updatedOrders = orders.map(order => {
					if (order._id === orderId) {
						return { ...order, courierPartner: newCourierPartner }; // Update order with the new courier partner
					}
					return order;
				});
				setOrders(updatedOrders);
				toast.success(`Courier Partner updated to ${newCourierPartner}`);
			})
			.catch(error => {
				console.error('Error updating courier partner:', error);
				toast.error('Failed to update courier partner');
			});
	};

	const handleTrackingIdChange = async (orderId, newTrackingId) => {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_API_URL}order/update-status/${orderId}`,
				{ trackingId: newTrackingId }
			);

			const { success, message, updatedOrder } = response.data;

			if (success) {
				// Assuming you have a function to update orders in your state
				setOrders(prevOrders => prevOrders.map(order => (order._id === orderId ? updatedOrder : order)));
				toast.success(message);
			} else {
				toast.error(message);
			}
		} catch (error) {
			console.error('Error updating tracking ID:', error);
			toast.error('Failed to update tracking ID');
		}
	};
	// State for order updates
	const [orderUpdates, setOrderUpdates] = useState([]);

	// Effect to update orderUpdates when orders are available
	useEffect(() => {
		if (orders.length > 0) {
			setOrderUpdates(
				orders.map((order) => ({
					orderId: order._id,
					orderStatus: order.orderStatus,
					courierPartner: order.courierPartner,
					trackingId: order.trackingId || '', // Ensure trackingId is initialized properly
				}))
			);
		}
	}, [orders]); // Only run when `orders` changes

	// Log orderUpdates to inspect values
	useEffect(() => {
		console.log('Order Updates:', orderUpdates);
	}, [orderUpdates]);

	const handleInputChange = (orderId, field, value) => {
		console.log("handle Input change for orderId:", orderId, "field:", field, "value:", value);

		setOrderUpdates((prevUpdates) =>
			prevUpdates.map((update) =>
				update.orderId === orderId ? { ...update, [field]: value } : update
			)
		);

		// Log the orderUpdates to verify the change
		setOrderUpdates((prevUpdates) => {
			const newUpdates = prevUpdates.map((update) =>
				update.orderId === orderId ? { ...update, [field]: value } : update
			);
			console.log("Updated orderUpdates:", newUpdates);
			return newUpdates;
		});
	};



	const handleUpdateOrder = (orderId) => {
		const orderToUpdate = orderUpdates.find((update) => update.orderId === orderId);
		if (!orderToUpdate.orderStatus || !orderToUpdate.courierPartner || !orderToUpdate.trackingId) {
			toast.error('Please fill out all required fields: Order Status, Courier Partner, and Tracking ID.');
			return; // Exit the function if validation fails
		}

		if (orderToUpdate.orderStatus === 'Shipped') {
			orderToUpdate.shippedDate = new Date(); // Set shippedDate to the current date
		} else if (orderToUpdate.orderStatus === 'Delivered') {
			orderToUpdate.deliveredDate = new Date(); // Set deliveredDate to the current date
		}
		axios
			.put(`${process.env.REACT_APP_API_URL}order/update-status/${orderId}`, orderToUpdate)
			.then((response) => {
				const updatedOrders = orders.map((order) =>
					order._id === orderId
						? {
							...order,
							orderStatus: orderToUpdate.orderStatus,
							courierPartner: orderToUpdate.courierPartner,
							trackingId: orderToUpdate.trackingId,
						}
						: order
				);
				setOrders(updatedOrders);
				toast.success('Order updated successfully!');
			})
			.catch((error) => {
				console.error('Error updating order:', error);
				toast.error('Failed to update order');
			});
	};


	const columns = [];
	for (const key of selectedFields) {
		let editable = renderEditable;
		const fieldPath = key.split('.');
		const headerLabel = Capitalize(fieldPath[fieldPath.length - 1]);
		if (key === "image" || key === "status" || key === "avtar" || key === "vendor" || key === "order_status") {
			editable = null;
		}

		//const headerLabel = Capitalize(key);

		columns.push({
			name: <b>{headerLabel}</b>,
			header: <b>{headerLabel}</b>,
			selector: (row) => {
				let value = row;
				for (const field of fieldPath) {
					value = value[field];
				}
				return value;
			},
			Cell: editable,
			style: {
				textAlign: "center",
			},
		});
	}

	columns.push({
		name: <b>Order Status</b>,
		id: "orderStatus",
		selector: "orderStatus",
		cell: (row) => (
			<select
				value={
					orderUpdates.find((update) => update.orderId === row._id)
						?.orderStatus || row.orderStatus
				}
				onChange={(e) =>
					handleInputChange(row._id, "orderStatus", e.target.value)
				}
				disabled={row.orderStatus === "Delivered"} // Disable dropdown if status is 'Delivered'
			>
				{statusOptions.map((status) => (
					<option key={status} value={status}>
						{status}
					</option>
				))}
			</select>
		),
		style: {
			textAlign: "center",
		},
	});

	columns.push({
		name: <b>Courier Partner</b>,
		id: 'courierPartner',
		selector: 'courierPartner',
		cell: (row) => (
			<select
				value={
					orderUpdates.find((update) => update.orderId === row._id)
						?.courierPartner || row.courierPartner
				}
				onChange={(e) =>
					handleInputChange(row._id, 'courierPartner', e.target.value)
				}
			>
				{courierOptions.map((partner) => (
					<option key={partner} value={partner}>
						{partner}
					</option>
				))}
			</select>
		),
		style: {
			textAlign: 'center',
		},
	});

	columns.push({
		name: <b>Tracking Id</b>,
		id: 'trackingId',
		selector: 'trackingId',
		cell: (row) => (
			<input
				type="text"
				value={
					orderUpdates.find((update) => update.orderId === row._id)?.trackingId ||
					''
				}
				onChange={(e) =>
					handleInputChange(row._id, 'trackingId', e.target.value)
				}
				placeholder="Enter Tracking ID"
			/>
		),
		style: {
			textAlign: 'center',
		},
	});




	if (multiSelectOption === true) {
		columns.push({
			name: (
				<button
					className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
					onClick={(e) => {
						if (window.confirm("Are you sure you wish to delete this item?"))
							handleRemoveRow();
					}}
				>
					Delete
				</button>
			),
			id: "delete",
			accessor: (str) => "delete",
			cell: (row) => (
				<div>
					<span>
						<input
							type="checkbox"
							name={row.id}
							defaultChecked={checkedValues.includes(row.id)}
							onChange={(e) => selectRow(e, row.id)}
						/>
					</span>
				</div>
			),
			style: {
				textAlign: "center",
			},
			sortable: false,
		});
	} else {
		columns.push({
			name: <b>Action</b>,
			id: "delete",
			accessor: (str) => "delete",
			cell: (row, index) => (
				<div>
					<span onClick={() => handleUpdateOrder(row._id)}>
						<button
							style={{
								width: 'auto',
								padding: '5px 10px',
								backgroundColor: '#007bff',
								color: '#fff',
								border: 'none',
								borderRadius: '5px',
								cursor: 'pointer',
								fontSize: '14px',
							}}
						>
							Update Order
						</button>
					</span>
					<span onClick={() => handleDeleteOrder(row._id)}>
						<i
							className="fa fa-trash"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "#e4566e",
							}}
						></i>
					</span>

					<span onClick={() => handleShowOrderDetails(row)}>
						<i
							className="fa fa-eye"
							style={{
								width: 35,
								fontSize: 20,
								padding: 11,
								color: "rgb(40, 167, 69)",
							}}
						></i>
						<Modal
							isOpen={open}
							toggle={onCloseModal}
							style={{ overlay: { opacity: 0.1 }, width: '80%' }}
						>
							<ModalHeader toggle={onCloseModal}>
								<h5 className="modal-title f-w-600" id="exampleModalLabel2">
									Order Details
								</h5>
							</ModalHeader>
							<ModalBody>
								<div>
									{selectedOrder && (
										<div style={{ display: 'flex', justifyContent: 'space-around' }}>
											<div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: "45%" }}>
												<h4>Ordered Person Details</h4>
												<h6>Ordered Date:{selectedOrder.createdAt}</h6>
												<p>Name: {selectedOrder.name}</p>
												<p>Email: {selectedOrder.email}</p>
												<p>Phone: {selectedOrder.phone}</p>
											</div>
											<div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: "45%" }}>
												<h4>Delivery Address</h4>
												<p>{selectedOrder.selectedAddress.name},<br />
													{selectedOrder.selectedAddress.mobile},<br />
													{selectedOrder.selectedAddress.address},<br />
													{selectedOrder.selectedAddress.landmark},<br />
													{selectedOrder.selectedAddress.locality} - {selectedOrder.selectedAddress.pincode}, <br />
													{selectedOrder.selectedAddress.district}, <br />
													{selectedOrder.selectedAddress.state}.<br />
												</p>
											</div>
										</div>
									)}

									<div>
										<h5>Ordered Products</h5>
										<div class="products-container">
											{selectedOrder && selectedOrder.products && selectedOrder.products.map(product => (
												<div class="product" >
													<p>{product.name}</p>
													<p>Categry : {product.category}</p>
													<p>SKU : {product.sku}</p>
													<p><del>₹{product.regularPrice}</del> ₹{product.offerPrice} - {product.offerPercentage}%</p>
													<p>Quantity : {product.quantity} {product.singleProductQuantity}</p>
												</div>
											))}
										</div>
										{selectedOrder && selectedOrder.paymentMethod && selectedOrder.paymentMethod == 'razorpay' ? (
											<div>
												<p>order ID: {selectedOrder.razorpayOrder.id}</p>
												<p>Payment ID: {selectedOrder.paymentId}</p>
												<p>Amount: {selectedOrder.razorpayOrder.amount / 100}</p>
												<p>Amount Paid: {selectedOrder.razorpayOrder.amount_paid}</p>
												<p>Order Status: {selectedOrder.razorpayOrder.status}</p>
											</div>
										) : ('')}

										<p>Payment Method : {selectedOrder && selectedOrder.paymentMethod}</p>
										<p>TotalAmount: ₹{selectedOrder && selectedOrder.totalAmount} (including shipping cost)</p>

									</div>
								</div>
							</ModalBody>
						</Modal>
					</span>
				</div>
			),
			style: {
				textAlign: "center",
			},
			sortable: false,
		});
	}
	return (
		<div>
			<Fragment>
				<DataTable
					data={orders}
					columns={columns}
					className={myClass}
					pagination={pagination}
					striped={true}
					center={true}
				/>

				<ToastContainer />
			</Fragment>
		</div>
	);
};

export default Datatable;
